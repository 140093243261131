
import React from 'react'
import { Link } from 'gatsby'
import cn from 'classnames'

import Port from '../components/Port'
import FEATURES from '../config/monitorFeatures'
import Price from '../helpers/Price'
import MonitorImage from '../components/MonitorImage'
import useBookmarks from '../components/useBookmarks'
import { usePopover } from '../helpers/Popover'


export default function MonitorTile({ data, stats = {}, showScaled = false }) {

	const { isBookmarked, BookmarkButton } = useBookmarks(data.slug)
	const { popoverHandlers } = usePopover()
	
	const features = React.useMemo(() => 
		Object.entries(FEATURES)
		.filter(([id, { check }]) => id !== 'curved' && check(data))
		.map(([id, { snippet, label, render }]) => snippet
			?	<span key={id} 
					className='feature po' 
					data-popover={id} 
					{...popoverHandlers}
				>
					{ render ? render(data) : label }
				</span>
			:	<span key={id} className='feature'>
					{ render ? render(data) : label }
				</span>
		)
	, [])

	return <div className='monitorTile'>
		
		<MonitorImage 
			relative={showScaled}
			data={data}
			// maxH={stats.maxheight}
			maxW={stats.maxwidth}
		/>

		<h2 className={cn('mtName', { pinned: isBookmarked })}>
			<Link to={`/monitors/${data.slug}/`}>
				{`${data.brand.name} ${data.model}`}
			</Link>
			{ BookmarkButton }
		</h2>

		<dl className='mtSpecs'>

			<div className='mtAttr'>
				<dt>Price from:</dt>
				<dd><Price value={data.price} /></dd>
			</div>
			
			<div className='mtAttr'>
				<dt>Size:</dt>
				<dd>
					{data.screenSize}"					
				</dd>
			</div>
			
			<div className='mtAttr'>
				<dt>Aspect:</dt>
				<dd>{data.aspectRatio.replace(':', ' : ')}</dd>
			</div>			


			<div className='mtAttr doubleColumn'>
				<dt>Resolution:</dt>
				<dd>{data.resolutionH} x {data.resolutionV}</dd>
			</div>
			
			<div className='mtAttr'>
				<dt>Density:</dt>
				<dd>{data.pixelDensity} PPI</dd>
			</div>

			<div className='mtAttr'>
				<dt>Refresh:</dt>
				<dd>{data.refreshRate} Hz</dd>
			</div>


			<div className='mtAttr'>
				<dt>Panel:</dt>
				<dd>
					<span className='po' data-popover={data.panelTech} {...popoverHandlers}>
						{data.panelTech}
					</span> { FEATURES.curved.check(data) && <>{' '}
						<small className='mtCurved'>curved</small>
					</> }
				</dd>
			</div>

			<div className='mtAttr'>
				<dt>Color:</dt>
				<dd>
					 {data.colorDepth} bit { !!data.frc && 
						<>+ <span className='po' data-popover='FRC' {...popoverHandlers}>FRC</span></> }
				</dd>
			</div>

			<div className='mtAttr'>
				<dt>Response:</dt>
				<dd>{data.responseTime} ms</dd>
			</div>


			<div className='mtAttr'>
				<dt>Contrast:</dt>
				<dd>{data.contrast} :1</dd>
			</div>			

			<div className='mtAttr'>
				<dt>Brightness:</dt>
				<dd>{data.brightness} cd/m²</dd>
			</div>

		</dl>

		<dl className='mtPortsFeatures'>

			<div className='mtAttr mtPorts'>
				<dt>Ports:</dt>
				<dd>
					{ data.ports.map((port, idx) => 
						<Port port={port} key={idx} />
					)}
				</dd>
			</div>

			{ !!features.length &&
				<div className='mtAttr mtFeatures'>
					<dt>Features:</dt>
					<dd>{ features }</dd>
				</div>
			}

		</dl>

	</div>
}
