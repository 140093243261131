
import React from 'react'
import { useRecoilValue } from 'recoil'

import MonitorListMenu from './MonitorListMenu'
import SortOrder from './SortOrder'
import MonitorTile from './MonitorTile'

import { 
	filteredProductsAtom,
	productsStatsAtom,
} from './atoms'

import '../styles/Tiles.sass'


export default function MonitorsTiles() {

	const [showScaled, setScaled] = React.useState(true)
	
	const stats = useRecoilValue(productsStatsAtom)
	const products = useRecoilValue(filteredProductsAtom)

	return <>

		<MonitorListMenu view='tiles'>

			<SortOrder />

			<label className='plOpt'>
				<input type='checkbox'
					checked={showScaled}
					// disabled={true}
					onChange={event => setScaled(event.target.checked) }
				/> Show relative size
			</label>

		</MonitorListMenu>

		<div className='productsTiles'>
			{ products.map(data =>
				<MonitorTile key={data.slug} 
					data={data} 
					stats={stats}
					showScaled={showScaled}
				/>
			)}
		</div>

	</>
}
