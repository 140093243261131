
import React from 'react'
import { useRecoilValue } from 'recoil'
import { Link, useScrollRestoration } from 'gatsby'
import cn from 'classnames'

import MonitorListMenu from './MonitorListMenu'
import SortOrder from './SortOrder'
import MonitorImage from '../components/MonitorImage'
import FEATURES from '../config/monitorFeatures'
import Port from '../components/Port'
import useBookmarks from '../components/useBookmarks'
import { usePopover } from '../helpers/Popover'
import formatValue from '../helpers/formatValue'

import { 
	filteredProductsAtom,
	productsStatsAtom,
} from './atoms'

import '../styles/Table.sass'


export default function MonitorsTable() {

	const products = useRecoilValue(filteredProductsAtom)
	const stats = useRecoilValue(productsStatsAtom)
	useScrollRestoration('monitorsTable')

	return <>

		<MonitorListMenu view='table'>
			<SortOrder />
		</MonitorListMenu>

		{/* <div className='productsTableWrapWrap'> */}
		{/* <div className='productsTableWrap'> */}
			<table className='productsTable'>
				<thead>
					<tr>
						<th colSpan='2'></th>
						<th>Model</th>
						<th>Price</th>
						<th>Size</th>
						<th>Curve</th>
						<th>Resolution</th>
						<th>Aspect</th>
						<th>Brightness</th>
						<th>Density</th>
						<th>Contrast</th>
						<th>Refresh</th>
						<th>Response</th>
						<th>Width</th>
						<th>Height</th>
						<th>Depth</th>
						<th>Ports</th>
						<th>Features</th>
					</tr>
				</thead>
				<tbody>
					{ products.map(data => 
						<MonitorRow key={data.slug} 
							data={data} 
							stats={stats}
							showScaled={true}
						/> 
					)}
				</tbody>
			</table>
		{/* </div> */}
		{/* </div> */}
	
	</>
}


function MonitorRow({ data, stats, showScaled }) {

	// const [bookmarks, setBookmarks] = useRecoilState(bookmarksAtom)
	// const isBookmarked = bookmarks.findIndex(slug => slug === data.slug) !== -1
	const { popoverHandlers } = usePopover()
	const { isBookmarked, BookmarkButton } = useBookmarks(data.slug)
	
	return <tr className={cn('pRow', { pinned: isBookmarked })}>
		
		<td className='mrImage'>
			<MonitorImage 
				relative={showScaled}
				data={data}
				// maxH={stats.maxheight}
				maxW={stats.maxwidth}
			/>
		</td>
		<td className='mrBookmark'>
			{ BookmarkButton }
		</td>

		<th className='mrModel'>
			<Link to={`/monitors/${data.slug}/`}>
				{`${data.brand.name} ${data.model}`}
			</Link>
		</th>

		<td className='v mrPrice'>{ formatValue(data.price, '$', { noComma: true }) }</td>

		<td className='v mrSize'>{data.screenSize}"</td>
		<td className='v'>{data.curveRadius || ''}</td>
		<td className='v mrRes'>
			{data.resolutionH} x {data.resolutionV} px
		</td>
		{/* <td className='v'>{data.aspectRatio.replace(':', ' : ')}</td> */}
		<td className='v mrRatio'>{data.aspectRatio}</td>
		<td className='v'>{data.brightness} cd/m²</td>
		<td className='v'>{data.pixelDensity} PPI</td>
		<td className='v'>{data.contrast}:1</td>
		<td className='v'>{data.refreshRate} Hz</td>
		<td className='v'>{data.responseTime} ms</td>
		<td className='v'>{formatValue(data.width, 'mm')}</td>
		<td className='v'>{formatValue(data.height, 'mm')}</td>
		<td className='v'>{formatValue(data.thick, 'mm')}</td>

		<td className='mrPorts'>
			{ data.ports && data.ports.map((port, idx) => 
				<Port port={port} key={idx} />
			)}
		</td>

		<td className='mrFeatures'>
			{ Object.entries(FEATURES)
				.filter(([id, { check }]) => id !== 'curved' && check(data) )
				.map(([id, { snippet, label, render }]) => snippet
					?	<span key={id} 
							className='feature po' 
							data-popover={id} 
							{...popoverHandlers}
						>
							{ render ? render(data) : label }
						</span>
					:	<span key={id} className='feature'>
							{ render ? render(data) : label }
						</span>
				// <span key={id} className='feature'>
				// 	{ label }
				// </span>
			)}
		</td>
		
	</tr>
}