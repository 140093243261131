
export default [
	{ id: 'price', label: 'Price', better: 'min', type: '$' },
	{ id: 'screenSize', label: 'Screen size', better: 'max', type: '"' },
	{ id: 'aspectRatioFloat', label: 'Aspect ratio', better: 'max', type: '' },
	{ id: 'resolutionV', label: 'Resolution', better: 'max', type: 'px' },
	{ id: 'pixelDensity', label: 'Pixel density', better: 'max', type: 'PPI' },
	{ id: 'refreshRate', label: 'Refresh rate', better: 'max', type: 'Hz' },
	{ id: 'responseTime', label: 'Response time', better: 'min', type: 'ms' },
	{ id: 'brightness', label: 'Brightness', better: 'max', type: 'cd/m²' },
	{ id: 'contrast', label: 'Contrast', better: 'max', type: ':1' },
	// { id: 'ports', label: 'Ports', better: 'max', type: ':1' },
]
