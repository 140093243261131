
import React from 'react'
import { useRecoilState } from 'recoil'
import produce from 'immer'
import cn from 'classnames'

import { Group } from '@visx/group'
import { extent } from 'd3-array'
import { localPoint } from '@visx/event'
import { 
	scaleLinear, 
	scaleBand, 
	scaleQuantize, 
} from '@visx/scale'

import { formValueAtoms } from './atoms'
import Symbol from '../helpers/Symbol'
import formatValue from '../helpers/formatValue'

import { formPadding } from '../config/layout'
const GRAPHHEIGHT = 70
const HANDLERADIUS = 10
const TICKSIZE = 12
const TRACKR = 5


export default function FormRange({
	attr,
	config: { options },
	width,
}) {

	const [isOpen, setOpen] = React.useState(attr.id === 'price' || attr.id === 'screenSize')
	const [draggingHandle, setDragging] = React.useState(null)

	const height = GRAPHHEIGHT
	const handleRadius = HANDLERADIUS
	if (width < height) width = height*2

	const margin = { 
		top: 0, 
		bottom: handleRadius*2 + TICKSIZE, 
		left: formPadding, 
		right: formPadding 
	}
	
	let xMax = (width || 250) - margin.left - margin.right
	let yMax = height - margin.top - margin.bottom

	const [values, setValues] = useRecoilState(formValueAtoms(attr.id))
	const setValue = (idx, v) => setValues(prev => produce(prev, draft => {
		draft[idx] = v
	}))

	const valuesIdx = values.map(v => options.findIndex(o => o[0] === v))
	const valuesLabels = valuesIdx.map(vIdx => options[vIdx][2] ?? options[vIdx][0])
	const single = valuesIdx.length === 1
	const isSet = valuesIdx.some((val, idx) => idx === 0 
		? val !== 0 
		: val !== options.length - 1)
	
	// Scales 

	const xScale = React.useMemo(() => scaleBand({
		domain: options.map((o, idx) => idx),
		paddingInner: 0.05,
		range: [0, xMax],
	})
	, [options, xMax])

	const yExtent = extent(options, o => o[1])

	const yScale = React.useMemo(() => scaleLinear({
		domain: [yExtent[0]-1, yExtent[1]],
		range: [yMax, 0],
	})
	, [options, yMax])

	const quantScale = React.useMemo(() => 
		scaleQuantize({
			domain: [0, xMax],
			range: options.map((o, idx) => idx),
		})
	, [xMax, options])


	// Tooltip

	const [tooltipIdx, setTooltipIdx] = React.useState(null)

	const tooltip = React.useMemo(() => {
		if (tooltipIdx === null) return null
		const left = xScale(tooltipIdx) + margin.left
		const [[handleIdx]] = valuesIdx.map((v, idx) => [idx, Math.abs(tooltipIdx - v)]).sort((a, b) => a[1] - b[1])
		return { 
			left,
			label: `${handleIdx === 0 ? attr.type === 'ms' ? "up to " : "from " : "up to "} ${formatValue(options[tooltipIdx][2] ?? options[tooltipIdx][0], attr.type)}`
		}
	}, [tooltipIdx, valuesIdx])

	// Scale ticks

	const scaleTicks = React.useMemo(() => {
		const ticks = []
		const lastLabel = formatValue(options[options.length - 1][2] ?? options[options.length - 1][0], attr.type, { inK: true })
		const avgLabelSize = (lastLabel.length * TICKSIZE * 0.9)
		let step = Math.ceil(avgLabelSize / xScale.bandwidth())
		for (let idx = 0; idx < options.length; idx++) {
			if (!(idx === 0 || idx === options.length - 1 || idx % step === 0) || (step > 1 && idx < options.length - 1 && idx > options.length - 3)) {
				continue
			}
			const x = xScale(idx) + xScale.bandwidth()/2
			const text = formatValue(options[idx][2] ?? options[idx][0], attr.type, { inK: true })
			ticks.push(
				<text key={idx} className='frTickLabel'
					x={x}
					y={yMax - 2 + HANDLERADIUS * 2}
					>{text}</text>,
				<rect key={`${idx}l`} className='frTick'
					width={1}
					height={HANDLERADIUS - 4}
					x={x}
					y={yMax + HANDLERADIUS}				
					rx={2}
				/>
			)
		}
		return ticks
	}, [options, width])

	// Handlers

	const handleChange = (optionsIdx, handleIdx = 0) => {
		if (draggingHandle === null && handleIdx === undefined)
			return
		setValue(draggingHandle ?? handleIdx, options[optionsIdx][0])
	}

	const handleDrag = event => {
		const { x } = localPoint(event) || { x:0 }
		const index = quantScale(x - margin.left)
		const [[handleIdx]] = valuesIdx.map((v, idx) => [idx, Math.abs(index - v)]).sort((a, b) => a[1] - b[1])
		setDragging(handleIdx)
		document.onselectstart = () => false
		handleChange(index, handleIdx)
	}

	const handleMove = event => {
		const { x } = localPoint(event) || { x: 0 }
		const index = quantScale(x - margin.left)
		setTooltipIdx(index)
		if (draggingHandle !== null) {
			handleChange(index)
		}
	}

	const handleStop = () => {
		setDragging(null)
		document.onselectstart = () => true
	}
	const handleLeave = () => {
		setDragging(null)
		setTooltipIdx(null)
		document.onselectstart = () => true
	}

	return <div 
		className={cn(`formFieldset formRange`, { isSet, isOpen })}
	>

		<div className={cn(`formLabel`, { isOpen })}
			onClick={() => setOpen(prev => !prev)}
		>
			<strong className='flTitle'>
				{attr.label}
			</strong> 
		
			{ (!isOpen && !isSet) && <span className='flDesc isClosed'>
				{ `${formatValue(options[0][attr.id === 'aspectRatioFloat' ? 2 : 0], attr.type)} - ${formatValue(options[options.length-1][attr.id === 'aspectRatioFloat' ? 2 : 0], attr.type, { inK: false })}` }
			</span> }

			{ isSet && <span className='flDesc isSet'>
				{ (valuesIdx[0] !== 0) && <>
					{ attr.type === 'ms' ? " up to " : " from " } 
					<strong>
						{ formatValue(valuesLabels[0], attr.type, { inK: true }) }
					</strong>
				</> }
				{ (values.length > 1 && valuesIdx[1] !== options.length-1) && <>
					{ valuesIdx[0] !== 0 ? " to " : "up to " }
					<strong>
						{ formatValue(valuesLabels[1], attr.type, { inK: true }) }
					</strong>
				</> }
			</span> }
					
			<Symbol id={isOpen ? 'up' : 'down'} />

		</div>

		{ !!tooltip &&
			<Tooltip left={tooltip.left} label={tooltip.label} /> 
		}

		{ isOpen &&

			<svg className='frSvg'
				viewBox={`0 0 ${width} ${height}`} 
				preserveAspectRatio='none' 
				className={cn('frSvg', { 
					isDragging: draggingHandle !== null ? 'isDraggin' : ''
				})}
				style={{
					// '--tickSize': `${TICKSIZE}px`
					'--tickSize': `${TICKSIZE}px`
				}}

			>
				<Group top={margin.top} left={margin.left}>

					{ options.map((o, idx) => {
						const height = (yMax - yScale(o[1] ?? 0) ) || 0
						return <rect key={idx}
							x={xScale(idx)}
							y={yMax - height}
							width={xScale.bandwidth()}
							height={height}
							className={cn('frB', { 
								'on': idx >= valuesIdx[0] && (single || idx <= valuesIdx[1]),
							})}
						/>
					})}

					{ scaleTicks }

					<rect
						y={yMax}
						width={xMax + TRACKR * 2}
						x={-TRACKR}
						rx={TRACKR}
						height={handleRadius}
						className='frTrackBgr'
					/>
					<rect
						x={xScale(valuesIdx[0])}
						y={yMax}
						width={ (
							single
								? xScale(options.length - 1) - xScale(valuesIdx[0]) + xScale.bandwidth()
								: xScale(valuesIdx[1]) - xScale(valuesIdx[0]) + xScale.bandwidth()
							) + TRACKR
						}
						rx={TRACKR}
						height={handleRadius}
						className='frTrack'
					/>

					{ valuesIdx.map((valueIdx, idx) =>
						<circle
							key={`h${idx}`}
							cx={xScale(valueIdx) + xScale.bandwidth() * idx}
							cy={yMax + handleRadius/2 }
							r={handleRadius}
							className={cn('frHandle', { isDragged: draggingHandle === idx })}
						/>
					)}

				</Group>
				
				<rect x={0} y={0}
					width={width}
					height={height}
					fill='transparent'
					// onTouchStart={handleTooltip}
					// onTouchMove={handleTooltip}
					// onTouchEnd={() => hideTooltip()}
					onMouseDown={handleDrag}
					onMouseMove={handleMove}
					onMouseUp={handleStop}
					onMouseLeave={handleLeave}
				/>

			</svg>
		}

	</div>
}


function Tooltip({ left, label }) {

	const ref = React.useRef(null)
	const [offset, setOffset] = React.useState(0)

	React.useEffect(() => {
		const t = ref.current.getBoundingClientRect()
		const p = ref.current.parentNode.getBoundingClientRect()
		const diff = (t.left + t.width + offset) - p.width
		setOffset( diff > 0 ? diff + 10 : 0 )
	}, [left, setOffset])

	return <div 
		ref={ref}
		className='frTooltip'
		style={{ left: `${left - offset}px` }}
	>
		{ label }
	</div>
}