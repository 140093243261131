
import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import produce from 'immer'
import cn from 'classnames'

import Port from './Port'
// import { usePopover } from '../helpers/Popover'
import Symbol from '../helpers/Symbol'
import PortSymbol from '../helpers/PortSymbol'
import { 
	formMapAtoms,
	formConfigAtom,
} from './atoms'


export default function MonitorPorts() {

	const [ports, setPorts] = useRecoilState(formMapAtoms('ports'))
	const [isOpen, setOpen] = React.useState(false)
	const config = useRecoilValue(formConfigAtom)
	const isSet = Object.keys(ports).length > 0

	const handleChange = (id, min, p) => {
		setPorts(prev => produce(prev, draft => {
			if (min > 0) {
				draft[id] = { 
					attrs: p.attrs, 
					name: p.name,
					min 
				}
			} else {
				delete draft[id]
			}
		}))
	}

	return <div className={cn(`formFieldset formCheckboxes`, { isSet, isOpen })}>

		<div className='formLabel'
			onClick={() => setOpen(prev => !prev)}
		>
			<strong className='flTitle'>
				Ports
			</strong>

			{ !isOpen && <span className='flDesc isClosed'>
				{ !isSet
					? "DisplayPort, HDMI, USB-C, audio, etc."
					: <strong>{ Object.values(ports).map(port => `${port.min > 1 ? `${port.min}x ` : '' }${port.name}`).join(", ") }</strong>
				}
			</span> }

			<Symbol id={isOpen ? 'up' : 'down'} />

		</div>

		{ isOpen && config.ports.map(p => 
			<PortField key={p.id}
				port={p}
				min={ports[p.id]?.min || 0}
				handleChange={handleChange}
			/>
		)}
	
		{/* <Link to='ports'>Port guide</Link> */}

	</div>
}


function PortField({ port, min, handleChange}) {

	const [checked, setChecked] = React.useState(min === 0 
		? [false, false]
		: min === 1
			? [false, true]
			: [true, true]
	)

	const onChange = event => {
		const isChecked = !!event.target.checked
		const idx = parseInt(event.target.value)
		setChecked(prev => produce(prev, draft => {
			draft[idx] = isChecked
		}))
	}

	const handleClick = event => {
		event.preventDefault()
		setChecked(prev => produce(prev, draft => {
			if ((port.max === 1 && draft[0]) || (draft[0] && draft[1]))
				return [false, false]
			for (let i = 0; i < 2; i++) {
				if (!draft[i]) {
					draft[i] = true
					break
				}
			}
		}))
	}

	React.useEffect(() => {
		handleChange(port.id, checked.filter(v => !!v).length, port.port)
	}, [checked])

	// const { popoverHandlers } = usePopover({ formSide: true })

	return <label
		className={cn('mfPort formCheckbox', { 'mfpSub': port.sub})}
		// data-popover={port.port.slug}
		// {...popoverHandlers}
		style={{
			background: `linear-gradient(to right, var(--pcColor2) ${port.pc}%, transparent ${port.pc}%)`
		}}
		onClick={handleClick}
	>

		<PortSymbol id={port.port.slug} />

		<Port port={port.port} nocount nopopover /> 

		{ port.max > 1 &&
			<input type='checkbox'
				checked={checked[1]}
				name={`${port.id}-min2`}
				value='1'
				onChange={onChange}
			/>
		}

		<input type='checkbox'
			checked={checked[0]}
			name={`${port.id}-min1`}
			value='0'
			onChange={onChange}
		/>

		{/* { opts.map((v, idx) =>
			<label key={v} 
				className={cn('mfpLabel', { active: min === v })}
			>
				<input type='checkbox'
					name={`${port.id}-min`}
					checked={min === v}
					onChange={onChange}
					value={v}
				/>{ v > 2 ? `${v}+` : v }
			</label>
		)} */}

	</label>
}
