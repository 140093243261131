
import React from 'react'
import { useRecoilValue } from 'recoil'
import { useScrollRestoration } from 'gatsby'

import MonitorForm from '../../components/MonitorForm'
import MonitorTiles from '../../components/MonitorTiles'
import MonitorCompare from '../../components/MonitorCompare'
import MonitorTable from '../../components/MonitorTable'
import MonitorChart from '../../components/MonitorChart'
import Meta from '../../helpers/Meta'
import { scopeAtom, viewAtom, showFormAtom } from '../../components/atoms'

import '../../styles/Products.sass'


export default function MonitorsTiles(props) {

	const scope = useRecoilValue(scopeAtom)
	const view = useRecoilValue(viewAtom)
	const showForm = useRecoilValue(showFormAtom)
	const scrollRestoration = useScrollRestoration('productList')

	return <>

		<Meta title="Monitors" url='/monitors' />
	
		{ (scope !== 'bookmarks' && showForm) && 
			<MonitorForm /> }
	
		<main 
			id='ProductList'
			className={`pl-${view ?? 'tiles'} ${showForm ? 'plFormVisible' : 'plFormHidden'}`}
			{...scrollRestoration}
		>

			{ view === 'grid' && <MonitorTiles /> }
			{ view === 'table' && <MonitorTable /> }
			{ view === 'chart' && <MonitorChart /> }
			{ view === 'compare' && <MonitorCompare /> }

		</main>
	
	</>
}
