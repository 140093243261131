
import React from 'react'
import { useRecoilState } from 'recoil'

import SORTATTRS from '../config/sortAttributes'
import Symbol from '../helpers/Symbol'
import { 
	sortAttrAtom,
	sortOrderAtom,
} from '../components/atoms'


const SORTORDERS = [
	{ id: 'max', label: "desc" },
	{ id: 'min', label: "asc" },
]


export default function SortOrder() {

	const [sortAttr, setSortAttr] = useRecoilState(sortAttrAtom)
	const [sortOrder, setSortOrder] = useRecoilState(sortOrderAtom)

	const handleSortAttr = event => {
		setSortAttr(event.target.value)
		setSortOrder(SORTATTRS[event.target.value].default)
	}

	// const handleSortOrder = event => {
	// 	setSortOrder(event.target.value)
	// }

	return <div className='plOpt'>

		Order by: <select
			value={sortAttr}
			onChange={handleSortAttr}
			onBlur={handleSortAttr}
			className='plSortOrderSelect'
		>
			{ Object.entries(SORTATTRS).map(([id, { label }]) => 
				<option key={id} value={id}>{ label }</option>
			)}
		</select>

		<span className='plSortOrderBtns'>
			{ SORTORDERS.map(({ id, label }) => 
				<button key={id} 
					className={`plSortOrderBtn ${sortOrder === id ? 'checked' : ''}`} 
					onClick={() => setSortOrder(id)}
				>
					<Symbol id={`sort-${label}`} />
				</button>
			)}
		</span>

	</div>
}
