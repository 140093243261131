
import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'


export default function ScaledImage({ 
	data, 
	maxH, 
	maxW,
	relative
}) {

	const alt = `${data.brand.name} ${data.model}`

	if (!data.image.file)
		return alt

	// Normal

	if (!relative) {
		return <div className='pImage'>
			<GatsbyImage image={getImage(data.image.file)} 
				fadeIn={false} 
				alt={alt}
			/>
		</div>
	}

	// Relative / Scaled

	const width = `${(data.width/maxW)*100}%`

	const ratio = (maxH || maxW)/maxW
	const paddingTop = !!maxH
		? `${(ratio - (ratio * (data.height/maxH)))*100}%`
		: '0px'

	return <div className='pImage pImageScaledWrap' 
		style={{ paddingTop }}
	>

		<div className='pImageScaled' 
			style={{ width }}
		>
			<GatsbyImage image={getImage(data.image.file)}
				fadeIn={false}
				alt={alt}
			/>
		</div>

	</div>
}
