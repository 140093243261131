
import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import Symbol from '../helpers/Symbol'

import { 
	productsStatsAtom,
	categoryStatsAtom,
	viewAtom,
	scopeAtom,
	showFormAtom,
} from '../components/atoms'

const VIEWS = [
	{ id: 'grid', label: 'grid' },
	{ id: 'table', label: 'table' },
	{ id: 'chart', label: 'chart' },
	{ id: 'compare', label: 'compare' },
]


export default function MonitorListMenu({ children }) {

	const stats = useRecoilValue(productsStatsAtom)
	const categoryStats = useRecoilValue(categoryStatsAtom)
	const [scope, setScope] = useRecoilState(scopeAtom)
	const [view, setView] = useRecoilState(viewAtom)
	const [showForm, setShowForm] = useRecoilState(showFormAtom)

	return <div className='plHeader'>

		<div className='plOpt plFormToggle'>
			<button className='plFormToggleBtn'
				onClick={() => setShowForm(prev => !prev)}
			>
				<Symbol id={ showForm ? 'left' : 'right'} /><Symbol id='form' />
			</button>
		</div>

		<div className='plOpt plStats'>
			Results: { stats.total === 0
				? <span className='plsFound red'>0</span>
				: <strong className='plsFound'>{stats.total}</strong>
			}
			<span className='plsSlash'>/</span>				
			<span className='plsTotal'>
				{categoryStats.monitors.total}
			</span>
		</div>

		{ scope === 'bookmarks' && <div className='plOpt'>
			<button onClick={() => setScope('form')}>
				<Symbol id='left' /> all results
			</button>
		</div> }

		<div className='plOpt mpView'>
			View as: { VIEWS.map(({ id, label }) => 
				<label key={id}>
					<input type='radio'
						name='view'
						value={id}
						checked={view === id}
						onChange={event => setView(event.target.value)}
						aria-label={label}
					/> { label }
				</label>
			)}
		</div>

		{ children }

	</div>
}
