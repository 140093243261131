
import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import produce from 'immer'
import cn from 'classnames'

import { usePopover } from '../helpers/Popover'
import Symbol from '../helpers/Symbol'
import FEATURES from '../config/monitorFeatures'
import { 
	formArrAtoms,
	formConfigAtom,
} from './atoms'


export default function FormCheckboxes() {

	const [isOpen, setOpen] = React.useState(false)
	const [values, setValues] = useRecoilState(formArrAtoms('features'))
	const config = useRecoilValue(formConfigAtom)
	const { popoverHandlers } = usePopover({ formSide: true })
	const isSet = values.length > 0

	const onChange = (key, value) => {
		setValues(prev => produce(prev, draft => { 
			const idx = draft.findIndex(k => k === key)
			if (value && idx === -1) {
				draft.push(key)
			} else if (!value && idx !== -1) {
				draft.splice(idx, 1)
			}
		}))
	}

	return <div className={cn(`formFieldset formCheckboxes`, { isSet, isOpen })}>

		<div className='formLabel'
			onClick={() => setOpen(prev => !prev)}
		>
			<strong className='flTitle'>
				Features
			</strong>

			{ !isOpen && <span className='flDesc isClosed'>
				{ !isSet
					? "HDR, G-SYNC, FreeSync, curved, Vesa mount, etc."
					: <strong>{ values.map(id => FEATURES[id].label).join(", ") }</strong>
				}
			</span> }

			<Symbol id={isOpen ? 'up' : 'down'} />

		</div>

		{ isOpen && 
			Object.entries(config.features).map(([id, pc]) => 
				<label key={id} 
					className='formCheckbox'
					data-popover={FEATURES[id].snippet ? id : ''}
					{...popoverHandlers}		
					style={{
						background: `linear-gradient(to right, var(--pcColor2) ${pc}%, transparent ${pc}%)`
					}}
				>
					<input type='checkbox'
						checked={values.includes(id)}
						onChange={e => onChange(id, !!e.target.checked)}
					/> { FEATURES[id].label }
				</label>
			)
		}

	</div>
}
// 