
import React from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import produce from 'immer'
import useDimensions from 'react-cool-dimensions'
import cn from 'classnames'

import { Group } from '@visx/group'
import { scaleLinear, scaleLog } from '@visx/scale'
import { AxisTop, AxisRight } from '@visx/axis'
import { GridRows, GridColumns } from '@visx/grid'

import MonitorImage from '../components/MonitorImage'
import formatValue from '../helpers/formatValue'
import Symbol from '../helpers/Symbol'
import MonitorListMenu from '../components/MonitorListMenu'
import MonitorTile from './MonitorTile'
import ATTRS from '../config/monitorAttributes'
import { 
	filteredProductsAtom,
	productsStatsAtom,
	formConfigAtom,
	bookmarksAtom,
	chartThumbsAtom,
	chartSizeScaleAtom,
	chartScalesAtom,
} from '../components/atoms'

import '../styles/Chart.sass'


export default function MonitorChart() {

	const { observe, width, height } = useDimensions()
	// const width = Math.max(dWidth, 500)
	// const height = Math.max(dHeight, 500)

	const products = useRecoilValue(filteredProductsAtom)
	const stats = useRecoilValue(productsStatsAtom)
	const bookmarked = useRecoilValue(bookmarksAtom)
	const config = useRecoilValue(formConfigAtom)
	const [tooltip, setTooltip] = React.useState(null)
	const [showThumbs, setShowThumbs] = useRecoilState(chartThumbsAtom)
	const [sizeScale, setSizeScale] = useRecoilState(chartSizeScaleAtom)
	const [scales, setScales] = useRecoilState(chartScalesAtom)

	const margin = { top: 0, bottom: 0, left: 0, right: 0 }

	let xMax = width - margin.left - margin.right
	let yMax = height - margin.top - margin.bottom

	const tickValuesX = scales.x === 'price' ? null
		: config.attrs[scales.x].options.map(o => o[0])

	const tickValuesY = scales.y === 'price' ? null
		: config.attrs[scales.y].options.map(o => o[0])

	const xScale = React.useMemo(() => scaleLog({
		domain: [stats[`min${scales.x}`], stats[`max${scales.x}`]],
		range: [sizeScale * 12, xMax - (sizeScale * 8)],
		// nice: true,
		// round: true
	})
	, [stats, xMax, scales.x, sizeScale])

	const yScale = React.useMemo(() => scaleLog({
		domain: [stats[`max${scales.y}`], stats[`min${scales.y}`]],
		range: [sizeScale * 6, yMax - (sizeScale * 6) - 20],
		// nice: true,
		// round: true,
	})
	, [stats, yMax, scales.y])

	const sScale = React.useMemo(() => scaleLinear({
		domain: [stats[`min${scales.s}`], stats[`max${scales.s}`]],
		range: [sizeScale*10, sizeScale*20],
	}), [stats, scales.s, height, sizeScale])

	const cScale = React.useMemo(() => scaleLinear({
		domain: [stats[`min${scales.c}`], stats[`max${scales.c}`]],
		range: ATTRS[scales.c].order === 'min'
			? ['green', 'red']
			: ['red', 'green']			
	}), [stats, scales.c])

	const flipScales = React.useCallback((which) => {
		setScales(prev => produce(prev, draft => {
			if (which === 'xy')	{
				draft.x = prev.y
				draft.y = prev.x
			} else {
				draft.s = prev.c
				draft.c = prev.s
			}
		}))
	}, [])

	const showTooltip = (data, x, y, width, height) => {
		const doc = document.body.getBoundingClientRect()
		const chart = document.getElementById('MonitorChart').getBoundingClientRect()
		const w = 400
		const h = 800
		let left = chart.left + margin.left + x + width/2
		let top = chart.top + margin.top + y - height/2
		if (top + h > doc.height)
			top = top + (doc.height - (top + h))
		if (left + w > doc.width)
			left = chart.left + margin.left + x - w - width/2
		setTooltip({
			data,
			left,
			top,
			width: w,
			height: h,
		})
	}

	const items = width === 0 ? null : products.map(p => {

		const width = sScale(p[scales.s])
		const height = width / p.aspectRatioFloat
		const color = cScale(p[scales.c])
		const x = xScale(p[scales.x])
		const y = yScale(p[scales.y])

		const handleClick = event => {
			event.stopPropagation()
			showTooltip(p, x, y, width, height)						
		}

		const transform = `translate(${x + margin.left - width/2}px, calc(${y + margin.top}px - 50%))`
		const sizeClass = height < 46 
			? 's' 
			: height < 100 
			? 'm' 
			: 'l'

		return showThumbs
			?	<div key={p.slug}
					className='mgImage'
					onClick={handleClick}
					style={{
						transform,
						width: `${width}px`,
						zIndex: y
					}}
				>
					<MonitorImage data={p} />
				</div>
			:	<div key={p.slug}
					className={cn('mgRect', `mgr-${sizeClass}`, { 
						isPinned: bookmarked.includes(p.slug),
					})}
					onClick={handleClick}
					style={{
						transform,
						width: `${width}px`,
						height: `${height}px`,
						background: color,
						zIndex: parseInt(y/10),
					}}
				>
					<h4 className='mgrModel'>
						{ bookmarked.includes(p.slug) && <Symbol id='star' /> }
						<strong>{p.brand.name}</strong> {p.model}
					</h4>
					<p className='mgrSpec'>
						{ formatValue(p[scales.s], ATTRS[scales.s].type, { inK: true }) }
					</p>
					<p className='mgrSpec'>
						{ formatValue(p[scales.c], ATTRS[scales.c].type, { inK: true }) }
					</p>
				</div>
	})

	return <>

		<MonitorListMenu view='chart'>

			{/* <div id='MonitorGraphOptions'> */}

			<div className='mgOptnWrap plOpt'>
				<label className='mgOption'>
					Y axis: <AttrSelect name='y' value={scales.y} change={setScales} />		
				</label>
				<button className='mgOptnFlip' 
					onClick={() => flipScales('xy')} 
					aria-label="Switch the X scale with the Y scale"
				>
					⇄
				</button>
				<label className='mgOption'>
					X axis: <AttrSelect name='x' value={scales.x} change={setScales} />
				</label>
			</div>

			<div className='mgOptnWrap plOpt'>
				<label className='mgOption'>
					Size: <AttrSelect name='s' value={scales.s} change={setScales} />				
				</label>
			</div>
			<div className='mgOptnWrap plOpt'>
				Scale: <input type='range' 
					value={sizeScale} 
					min='2'
					max='12'
					step='1'
					onChange={event => setSizeScale(parseInt(event.target.value))} 
				/>
			</div>
			
			<div className='mgOptnWrap plOpt'>
				<label>
					<input type='checkbox'
						onChange={() => setShowThumbs(prev => !prev)}
						checked={showThumbs}
					/> show images
				</label>
			</div>
			
			<div className='mgOptnWrap plOpt'>
				<label className='mgOption'>
					Color: <AttrSelect name='c' value={scales.c} change={setScales} disabled={showThumbs} />
				</label>
			</div>
			<div id='mgColorLegendWrap'
				className={cn('mgOptnWrap plOpt', { disabled: showThumbs })}
			>
				<div id='mgColorLegend'>
					{ cScale.ticks(products.length).map((p, idx) => <div key={idx} 
						className='mgclc'
						style={{ background: cScale(p) }}
					/> ) }
				</div>
				{ cScale.domain().map((val, idx) => <span key={idx}
					className='mgclv'
				>
					{ formatValue(val, ATTRS[scales.c].type) }
				</span>)}
			</div>

			{/* </div> */}

		</MonitorListMenu>
			
		<div id='MonitorGraphWrap' ref={observe}>

			{ items }

			<svg id='MonitorChart'
				viewBox={`0 0 ${width} ${height}`} 
				preserveAspectRatio='xMidYMax slice' 
				style={{ width: `${width}px`, height: `${height}px` }}
				onMouseDown={() => {
					setTooltip(null)
				}}
			>
				<Group top={margin.top} left={margin.left}>

					<GridRows
						scale={yScale}
						width={xMax}
						strokeDasharray="1,3"
						stroke={'#777'}
						pointerEvents="none"
						tickValues={tickValuesY}
					/>
					<GridColumns
						// top={margin.top}
						scale={xScale}
						height={yMax}
						strokeDasharray="1,3"
						stroke={'#888'}
						pointerEvents="none"
						tickValues={tickValuesX}
					/>

					<AxisTop
						scale={xScale}
						top={yMax}
						// hideAxisLine={scales.y !== 'price'}
						hideAxisLine={true}
						hideZero={true}
						tickValues={tickValuesX}
						tickClassName='mgbtick'
						tickFormat={(value, idx) => scales.x === 'aspectRatioFloat'
							? config.attrs.aspectRatioFloat.options[idx][2]
							: formatValue(value, ATTRS[scales.x].type, { inK: true })
						}
					/>
					<AxisRight
						scale={yScale}
						hideAxisLine={true}
						hideZero={true}
						tickClassName='mgltick'
						tickValues={tickValuesY}
						tickFormat={(value, idx) => scales.y === 'aspectRatioFloat'
							? config.attrs.aspectRatioFloat.options[idx][2]
							: formatValue(value, ATTRS[scales.y].type, { inK: true })
						}
					/>

				</Group>

			</svg>

		</div>

		{ !!tooltip &&
			<div className='mgTile'
				style={{ 
					top: tooltip.top, 
					left: tooltip.left, 
					width: tooltip.width,
					// height: tooltip.height
				}}
			>
				<MonitorTile
					data={tooltip.data}
				/>
			</div>
		}

	</>
}


function AttrSelect({name, value, change, disabled = false}) {
	return <select
		aria-label="Select attribute"
		value={value}
		disabled={disabled}
		onChange={e => change(prev => produce(prev, draft => {
			draft[name] = e.target.value
		}))}
	>
		{ Object.keys(ATTRS).map(attrId =>
			<option key={attrId} value={attrId}>
				{ ATTRS[attrId].label.toLowerCase() }
			</option>)}
	</select>
}


// const SHAPES = [
// 	['screen', 'screen'],
// 	['image', 'image'],
// ]

// function ShapeSelect({value, change}) {
// 	return <select
// 		aria-label="Select shape"
// 		value={value}
// 		onChange={e => change(prev => e.target.value)}
// 	>
// 		{ SHAPES.map(([id, label]) =>
// 			<option key={id} value={id}>
// 				{ label }
// 			</option>
// 		)}
// 	</select>
// }


