
import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import produce from 'immer'
import cn from 'classnames'

import { 
	formArrAtoms,
	formConfigAtom,
} from './atoms'
import Symbol from '../helpers/Symbol'
import { usePopover } from '../helpers/Popover'
import PANELTYPES from '../config/panelTypes'


export default function MonitorPanels() {

	const [values, setValues] = useRecoilState(formArrAtoms('panelTypes'))
	const config = useRecoilValue(formConfigAtom)
	const [isOpen, setOpen] = React.useState(false)
	const { popoverHandlers } = usePopover({ formSide: true })
	const isSet = values.length < Object.keys(config.panelTypes).length

	const onChange = (key, value) => {
		setValues(prev => produce(prev, draft => { 
			const idx = draft.findIndex(k => k === key)
			if (value && idx === -1) {
				draft.push(key)
			} else if (!value && idx !== -1) {
				draft.splice(idx, 1)
			}
		}))
	}

	return <div className={cn(`formFieldset formCheckboxes`, { isSet, isOpen })}>

		<div className='formLabel'
			onClick={() => setOpen(prev => !prev)}
		>
			<strong className='flTitle'>
				Panel types
			</strong>

			{ !isOpen && <span className='flDesc isClosed'>
				{ !isSet
					? "IPS, VA, TN, QLED, OLED"
					: <strong>{ values.map(id => PANELTYPES[id].label).join(", ") }</strong>
				}
			</span> }

			<Symbol id={isOpen ? 'up' : 'down'} />

		</div>

		{ isOpen &&		
			Object.entries(config.panelTypes).map(([id, pc]) => 
				<label key={id} 
					className='formCheckbox'
					data-popover={id}
					{...popoverHandlers}
					style={{ 
						background: !!pc
							? `linear-gradient(to right, var(--pcColor2) ${pc}%, transparent ${pc}%)`
							: 'transparent'
					}}
				>
					<input type='checkbox'
						checked={values.includes(id)}
						onChange={e => onChange(id, !!e.target.checked)}
					/> { PANELTYPES[id].label }
				</label>
			)
		}

	</div>
}
