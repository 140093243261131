
import React from 'react'
import useDimensions from 'react-cool-dimensions'
import { useRecoilValue } from 'recoil'

import MonitorPorts from './MonitorPorts'
import MonitorFeatures from './MonitorFeatures'
import MonitorPanels from './MonitorPanels'
import FormRange from './FormRange'
import ATTRS from '../config/attributes'
import { formPadding } from '../config/layout'
// import Symbol from '../helpers/Symbol'

import { 
	formConfigAtom, 
	// showFormAtom,
	// productsStatsAtom,
	// categoryStatsAtom,
} from '../components/atoms'

import '../styles/Form.sass'


export default function MonitorForm() {

	const { observe, width } = useDimensions()
	const config = useRecoilValue(formConfigAtom)
	// const setShowForm = useSetRecoilState(showFormAtom)
	// const stats = useRecoilValue(productsStatsAtom)
	// const categoryStats = useRecoilValue(categoryStatsAtom)

	// console.log(width)

	return <form id='MonitorForm' 
		ref={observe}
		onSubmit={event => event.preventDefault()}
		style={{ '--formPadding': `${formPadding}px` }}
	>

		{/* <div id='mfHeader'>
			
			<strong><em>Sort by:</em></strong>

			<button id='FormToggle'
				onClick={() => setShowForm(false)}
			>
				<Symbol id='left' />
			</button>

		</div> */}

		{ ATTRS.map(attr =>
			<FormRange key={attr.id} 
				attr={attr} 
				config={config.attrs[attr.id]}
				width={width || 300}
			/>
		)}

		<MonitorFeatures />

		<MonitorPanels />

		<MonitorPorts />

	</form>
}

