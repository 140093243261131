
import React from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { Link } from 'gatsby'
import { scaleLinear } from '@visx/scale'
import { extent } from 'd3-array'
import produce from 'immer'
import cn from 'classnames'

import MonitorListMenu from './MonitorListMenu'
import useBookmarks from '../components/useBookmarks'
import { usePopover } from '../helpers/Popover'
import MonitorImage from '../components/MonitorImage'
import formatValue from '../helpers/formatValue'
import SortOrder from './SortOrder'
import Port from '../components/Port'
import { TYPES as HDRTYPES } from '../components/HDR'
import { TYPES as FREESYNCTYPES } from '../components/FreeSync'
import { TYPES as GSYNCTYPES } from '../components/GSYNC'

import { 
	filteredProductsAtom,
	compareStartAtom,
	compareColumnsAtom,
	compareKeepersAtom,
} from './atoms'

import '../styles/Compare.sass'

const COLOR = '#eee'
const COLORX = 'rgb(255,171,145)'
const COLORGOOD = 'rgb(139,195,74)'
const COLORBAD = 'rgb(220,237,200)'


export default function MonitorsCompare() {

	const products = useRecoilValue(filteredProductsAtom)
	const [start, setStart] = useRecoilState(compareStartAtom)
	const [columns, setColumns] = useRecoilState(compareColumnsAtom)
	const [keepers, setKeepers] = useRecoilState(compareKeepersAtom)
	const keepersLen = keepers.length

	const monitors = React.useMemo(() => {
		const payload = [...keepers]
		let idx = start
		while (payload.length < columns && products.length > idx) {
			if (!keepers.includes(products[idx]))
				payload.push(products[idx])
			idx++
		}
		return payload
	}, [keepers, columns, start, products])

	const handleKeep = event => {
		setKeepers(prev => produce(prev, draft => {
			const slug = event.target.value
			const entry = products.find(p => p.slug === slug)
			const keepersIdx = draft.findIndex(p => p.slug === slug)
			if (keepersIdx > -1) {
				draft.splice(keepersIdx, 1)
			} else {
				draft.push(entry)
			}
		}))
	}

	const handleNext = () => {
		let skip = 0
		for (let keeper of keepers) {
			const idx = products.findIndex(p => keeper.slug === p.slug)
			if (idx !== -1 && idx === start+1 || idx === start)
				skip++
		}
		setStart(prev => Math.min(prev+1+skip, products.length-columns+keepers.length))
	}

	const handlePrev = () => {
		let skip = 0
		for (let keeper of keepers) {
			const idx = products.findIndex(p => keeper.slug === p.slug)
			if (idx !== -1 && idx === start-1 || idx === start)
				skip++
		}
		setStart(prev => Math.max(prev-1-skip, 0)) 
	}

	const handleShrink = () => {
		setColumns(prev => prev-1)
	}

	const handleExpand = () => {
		setColumns(prev => prev+1)
	}

	return <>

		<MonitorListMenu>

			<SortOrder />

			<div id='mcgNavColumns' className='plOpt'>
				Columns: 
				<button onClick={handleShrink}>
					-
				</button>
				<ColumnCount 
					columns={columns}
					setColumns={setColumns}
					max={products.length}
				/>
				<button onClick={handleExpand}>
					+
				</button>
			</div>

		</MonitorListMenu>

		<div id='mcGrid'
			style={{
				gridTemplateColumns: keepersLen > 0
					? `max-content ${[...Array(keepersLen).fill('var(--columnWidth)')].join(' ')} var(--separatorWidth)`
					: 'max-content'
			}}
		>

			{ keepersLen > 0 && 
				<div id='mcgSeparator'
					style={{
						gridArea: `1 / ${keepersLen + 2} / 31 / ${keepersLen + 3}`
					}}
				>
				</div> 
			}

			<div id='mcgNav'>

				<button
					onClick={handlePrev}
					disabled={start === 0}
				>
					Prev
				</button>
				<button
					onClick={handleNext}
					disabled={start >= products.length - 1}
				>
					Next
				</button>

			</div>

			{ monitors.map(monitor =>
				<div key={monitor.slug}>
					<label key={monitor.slug}>
						<input type='checkbox'
							onChange={handleKeep}
							value={monitor.slug}
							checked={keepers.includes(monitor)}
						/> keep
					</label>
				</div>
			)}

			<Images products={monitors} keepersLen={keepersLen} />

			{ monitors.map((monitor, idx) => 
				<ModelLink key={monitor.slug} 
					monitor={monitor} 
					gridColumn={ keepersLen > 0 ? (idx < keepersLen ? idx+2 : idx+3) : idx+2 }
				/>
			)}

			{ ATTRS.map(attr =>
				<Row key={attr.id} 
					attr={attr} 
					products={monitors} 
					keepersLen={keepersLen}
				/>
			)}

		</div>

	</>
}


function Images({ products, keepersLen }) {

	const [maxH, maxW] = React.useMemo(() => {
		let maxH = 0
		let maxW = 0
		for (let item of products) {
			if (maxH < item.height)
				maxH = item.height
			if (maxW < item.width)
				maxW = item.width
		}
		return [maxH, maxW]
	}, [products])

	return products.map((monitor, idx) => 
		<div key={monitor.slug}
			// style={{ gridColumn: `${(idx < keepersLen ? idx : idx+1 ) + 1}` }}
			style={{ gridColumn: `${ keepersLen > 0 ? (idx < keepersLen ? idx+2 : idx+3) : idx+2 }` }}
			className='mcgImage'
		>
			<MonitorImage 
				relative={true}
				data={monitor}
				maxH={maxH}
				maxW={maxW}
			/>
		</div>
	)
}


function ModelLink({ monitor, gridColumn }) {
	const { isBookmarked, BookmarkButton } = useBookmarks(monitor.slug)
	return <div 
		className='mcgModel'
		style={{ gridColumn }}
	>
		<Link to={`/monitors/${monitor.slug}/`}
			className={cn({ pinned: isBookmarked })}
		>
			{ `${monitor.brand.name} ${monitor.model}` }
		</Link> { BookmarkButton }
	</div>
}


function Row({ attr, products, keepersLen }) {

	const domain = extent(products.map(p => attr.score ? attr.score(p) : p[attr.id]))

	const { popoverHandlers } = usePopover()

	const scale = domain[0] === domain[1] ? null : scaleLinear({
		domain,
		range: attr.order === 'desc' ? [COLORBAD, COLORGOOD] : [COLORGOOD, COLORBAD]
	})

	return <>

		<div className='mcgAttr'>
			{ attr.snippet 
				?	<span
						className='po'
						data-popover={attr.snippet}
						{...popoverHandlers}
					>{attr.label}</span> 
				:	attr.label
			}
		</div>

		{ products.map((p, idx) => {
			
			const score = attr.score ? attr.score(p) : p[attr.id]
			const value = attr.type 
				? formatValue(p[attr.id], attr.type) 
				: !!attr.render
				? attr.render(p, attr.id)
				: p[attr.id] 

			const gridColumn = keepersLen > 0 ? (idx < keepersLen ? idx+2 : idx+3) : idx+2

			return <div key={p.slug} 
				className={`mcgVal mcgv-${attr.id}`}
				style={{ 
					gridColumn,
					background: score === 0
						? COLORX
						: !scale 
						? COLOR 
						: scale(attr.score ? attr.score(p) : p[attr.id])
					,
				}}
			>
				{ (attr.valueSnippet && score !== 0)
					?	<span
							className='po'
							data-popover={attr.snippet}
							{...popoverHandlers}
						>{ value }</span>
					: value
				}
			</div>
		})}

	</>
}


function ColumnCount({ columns, setColumns, max }) {

	const handleChange = event => {
		console.log(event.target.value)
		setColumns(parseInt(event.target.value))
	}

	return <select
		onChange={handleChange}
		value={columns}
	>
		<option value={max}>all</option>
		{ [...Array(Math.min(max, 10)).fill(1)].map((i, idx) => 
			<option key={idx} value={idx+2}>{idx+2}</option>
		) }
	</select>
}


function MmAndInch(product, attr) {
	return `${formatValue(product[attr], 'mm')} | ${formatValue(product[attr], 'in')}`
}

const ATTRS = [

	{	id: 'price',
		label: "Price",
		type: "$",
		order: 'asc',
	},
	{	id: 'panelTech',
		label: "Panel tech",
		order: 'desc',
		score: product => {
			switch (product.panelTech) {
				case 'OLED': return 6
				case 'QLED': return 5
				case 'IPS': return 4
				case 'VA': return 3
				case 'TN': return 2
			}
		}
	},
	{	id: 'screenSize',
		label: "Screen size",
		type: '"',
		order: 'desc',
	},

	{	id: 'resolutionH',
		label: "Resolution H.",
		type: 'px',
		order: 'desc',
	},
	{	id: 'resolutionV',
		label: "Resolution V.",
		type: 'px',
		order: 'desc',
	},
	{	id: 'pixelDensity',
		label: "Pixel density",
		type: 'PPI',
		order: 'desc',
	},
	{	id: 'aspectRatio',
		label: "Aspect ratio",
		order: 'desc',
		score: product => product.aspectRatioFloat
	},
	{	id: 'curveRadius',
		label: "Curve radius",
		order: 'asc',
		score: ({ curveRadius }) => curveRadius > 0 ? curveRadius : 5000,
		render: ({ curveRadius }) => curveRadius > 0
			? curveRadius
			: '✗',
	},

	{	id: 'colorDepth',
		label: "Color depth",
		type: 'bit',
		order: 'desc',
	},
	{	id: 'brightness',
		label: "Brightness",
		type: 'cd/m²',
		order: 'desc',
	},
	{	id: 'contrast',
		label: "Contrast",
		type: ':1',
		order: 'desc',
	},

	{	id: 'hdr',
		label: "HDR",
		order: 'desc',
		snippet: 'HDR',
		valueSnippet: true,
		render: ({ hdr }) => hdr
			? HDRTYPES[hdr].name
			: '✗',
		score: ({ hdr }) => hdr
			? HDRTYPES[hdr].score || 0
			: 0
	},

	{	id: 'refreshRate',
		label: "Refresh rate",
		type: 'Hz',
		order: 'desc',
	},
	{	id: 'responseTime',
		label: "Response time",
		type: 'ms',
		order: 'asc',
	},

	{	id: 'freesync',
		label: "FreeSync",
		order: 'desc',
		snippet: 'FreeSync',
		valueSnippet: true,
		render: ({ freesync }) => freesync
			? FREESYNCTYPES[freesync].name
			: '✗',
		score: ({ freesync }) => freesync
			? FREESYNCTYPES[freesync].score || 0
			: 0
	},
	{	id: 'gsync',
		label: "G-SYNC",
		order: 'desc',
		snippet: 'G-SYNC',
		valueSnippet: true,
		render: ({ gsync }) => gsync
			? GSYNCTYPES[gsync].name
			: '✗',
		score: ({ gsync }) => gsync
			? GSYNCTYPES[gsync].score || 0
			: 0
	},

	{	id: 'ports',
		label: "Ports",
		order: 'desc',
		score: ({ ports }) => {
			let score = 0
			for (let port of ports) {
				const count = port.count || 1
				if (/DisplayPort/.test(port.slug)) {
					score += (count * (2 + port.attrs.version > 1.2 ? 2 : 1)) + 0.11
				} else if (/HDMI/.test(port.slug)) {
					score += (count * (2 + port.attrs.version > 1.4 ? 2 : 1)) + 0.13
				} else if (/USB-C/.test(port.slug) && !!port.attrs?.dp) {
					score += (count * 2) + 0.24
				} else {
					score += count + 0.05
				}
			}
			return score
		},
		render: ({ ports }) => ports.map((port, idx) => 
			<Port key={idx} port={port} />
		)
	},

	{	id: 'width',
		label: "Width",
		order: 'desc',
		render: MmAndInch,
	},
	{	id: 'height',
		label: "Height",
		order: 'desc',
		render: MmAndInch,
	},
	{	id: 'thick',
		label: "Depth",
		order: 'asc',
		render: MmAndInch,
	},


	{	id: 'speakers',
		label: "Speakers",
		order: 'desc',
		score: ({ speakers, speakersWatt }) => {
			return (!!speakers && speakers !== 'no')
				? (speakers === 'stereo' ? 2 : 1) + (speakersWatt || 0)
				: 0
			},
		render: ({speakers, speakersWatt}) => (speakers && speakers !== 'no')
			?	`${speakers} ${speakersWatt ? `${speakersWatt}W` : ''}`
			: "✗"
	},
	{	id: 'hdcp',
		label: "HDCP",
		order: 'desc',
		snippet: 'HDCP',
		valueSnippet: true,
		score: ({ hdcp }) => hdcp ? 1 : 0,
		render: ({ hdcp }) => hdcp ? "HDCP 2.2" : "✗"
	},
	{	id: 'kvm',
		label: "KVM switch",
		order: 'desc',
		snippet: 'KVM',
		score: ({ kvm }) => kvm ? 1 : 0,
		render: ({ kvm }) => kvm ? "KVM switch" : "✗",
	},
	{	id: 'pivot',
		label: "Pivot",
		order: 'desc',
		snippet: 'pivot',
		score: ({ pivot }) => pivot ? 1 : 0,
		render: ({ pivot }) => pivot ? "pivot" : "✗",		
	},
	{	id: 'backlight',
		label: "Backlight",
		order: 'desc',
		score: ({ backlight }) => (backlight && backlight !== 'no')  ? 1 : 0,
		render: ({ backlight }) => (backlight && backlight !== 'no') ? "backlight" : "✗",		
	},
	{	id: 'pbp',
		label: "PbP",
		order: 'desc',
		snippet: 'picture-by-picture',
		score: ({ pbp }) => pbp ? 1 : 0,
		render: ({ pbp }) => pbp ? "Picture by Picture" : "✗",
	},
	
	{	id: 'vesa',
		label: "Vesa mount",
		order: 'desc',
		snippet: 'Vesa-mount',
		score: ({ vesa }) => Array.isArray(vesa) ? vesa.length : 0,
		render: ({ vesa }) => Array.isArray(vesa) ? vesa.join(", ") : "✗",
	},
	

]